import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/analytics';

export function firebaseIntializeApp() {
  const firebaseConfig = {
    apiKey: "AIzaSyCYTDOjj8Wi4ZRIRr38LWzNsGUteekTmpQ",
    authDomain: "david-lemayian-c8f80.firebaseapp.com",
    projectId: "david-lemayian-c8f80",
    storageBucket: "david-lemayian-c8f80.appspot.com",
    messagingSenderId: "941013347189",
    appId: "1:941013347189:web:e963ac20d84ca93539413c",
    measurementId: "G-81KGWW5EPN"
  };

  firebase.initializeApp(firebaseConfig);
}

// FirebaseEffects
export function FirebaseEffects() {
  const location = useLocation();
  const analytics = firebase.analytics();

  // Analytics
  useEffect(() => {
    const pagePath = location.pathname + location.search;
    analytics.setCurrentScreen(pagePath);
    analytics.logEvent('page_view', { pagePath });
  }, [location, analytics]);

  return null;
}
